/* eslint-disable indent */
import React from 'react';
import {
  Flex,
  List,
  ListItem,
  Box,
  Grid,
  Icon,
} from '@chakra-ui/core';
import {
  Redirect,
  Switch,
  NavLink,
  useLocation,
  useHistory,
} from 'react-router-dom';

/**
 * For now using some available icons for admin portal,
 * but need to replace with our SVG icons.
*/
import { Icon as IconifyIcon } from '@iconify/react';
import usersSolid from '@iconify/icons-clarity/users-solid';
import outlineNavigateNext from '@iconify/icons-ic/outline-navigate-next';
import templateIcon from '@iconify/icons-carbon/template';
import { useQuery } from '@apollo/client';

import PrivateRoute from '../../core/privateRoute';
import Customers from './Customers';
import DefaultTemplates from './DefaultTemplates';

import {
  Logout,
  // Button,
  // ButtonTypes,
  Text,
  TextTypes,
} from '../../components';
import { getUserDetails, handleSignOut } from '../../utils/auth';
import colors from '../../variables/colors';
import { GET_SELECTED_CUSTOMER_NAME, GET_SELECTED_CUSTOMER_ID }
  from '../../variables/globalVariables';

interface ISideMenuOption {
  id: string;
  path: string;
  title: string;
  sideMenuIcon?: any;
  leftMenuIcon?: any;
  component: any;
}

const SideMenuOptions: ISideMenuOption[] = [
  {
    id: 'customers',
    path: '/admin/customers',
    title: 'Customers',
    component: Customers,
    leftMenuIcon: usersSolid,
  },
  {
    id: 'templates',
    path: '/admin/templates',
    title: 'Default Templates',
    component: DefaultTemplates,
    leftMenuIcon: templateIcon,
  },
];

const getNavBarHeader = (
  pathname: string,
  customerName: string,
  customerId: string,
): string | null => {
  switch (pathname) {
    case '/admin/dashboard':
      return 'DASHBOARD';
    case '/admin/customers':
      return 'CUSTOMERS & MANAGEMENT';
    case '/admin/templates':
      return 'DEFAULT TEMPLATES';
    case `/admin/customers/${customerId}`:
      return customerName ? customerName.toUpperCase() : null;
    default:
      return null;
  }
};

const AdminDashboard = () => {
  const { pathname } = useLocation();
  const userDetails = getUserDetails();
  const history = useHistory();
  const { data } = useQuery(GET_SELECTED_CUSTOMER_NAME);
  const { selectedCustomerName: customerName } = data;
  const { data: dataId } = useQuery(GET_SELECTED_CUSTOMER_ID);
  const { selectedCustomerId: customerId } = dataId;
  const {
    userRoles: { edges: userRoleEdges },
  } = userDetails;
  const [userRoleNode] = userRoleEdges;
  const role = userRoleNode?.node?.role;
  const { firstName, lastName } = userRoleNode?.node?.user;

  const handleSignout = async () => {
    await handleSignOut();
    history.push('/auth');
  };

  if (!role || (role?.name !== 'Admin')) {
    return (
      <Redirect
        to="/auth"
      />
    );
  }

  /* TODO: Check whether customer or admin is accessing /admin */
  return (
    <Grid
      gridTemplateColumns="300px 1fr"
      gridTemplateRows="70px 1fr"
      width="100%"
    >
      <Box
        gridRow="1/3"
        backgroundColor="darkContainerBorderColor"
        paddingX={4}
        paddingTop={4}
      >
        <Flex
          direction="column"
          height="100%"
        >
          {/* Application logo */}
          <Icon
            name="app"
            alignSelf="center"
            width="100%"
            height="auto"
            marginBottom={12}
          />
          {/* Side menu options */}
          <List
            as="aside"
            d="flex"
            flexDir="column"
            justifyContent="center"
            paddingLeft={8}
            spacing={8}
          >
            {
              SideMenuOptions.map((sideMenuOption: ISideMenuOption) => (
                <ListItem
                  d="flex"
                  key={sideMenuOption.id}
                  color="activePrimaryColor"
                  fontSize="lg"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <NavLink
                    activeStyle={{
                      borderBottomWidth: 2,
                      borderBottomColor: colors.activePrimaryColor,
                    }}
                    to={sideMenuOption.path}
                    style={{ flexGrow: 1 }}
                  >
                    <Flex
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Flex alignItems="center">
                        <IconifyIcon
                          icon={sideMenuOption.leftMenuIcon}
                          style={{ marginRight: '8px' }}
                        />
                        {sideMenuOption.title}
                      </Flex>
                      <IconifyIcon icon={outlineNavigateNext} />
                    </Flex>
                  </NavLink>
                </ListItem>
              ))
            }
          </List>
        </Flex>
      </Box>
      {/* Application nav bar */}
      <Flex
        padding={4}
        justifyContent={
          pathname === `/admin/customers/${customerId}`
            ? 'center'
            : 'space-between'
        }
        backgroundColor="#F4F6F8"
      >
        <Flex
          alignItems="center"
          marginX={
            pathname === `/admin/customers/${customerId}`
              ? 'auto'
              : null
          }
        >
          <Text
            type={TextTypes.body}
            fontWeight={
              pathname === `/admin/customers/${customerId}`
                ? 'bold'
                : null
            }
            fontSize={20}
            color="headerText"
          >
            {
              getNavBarHeader(pathname, customerName, customerId)
            }
          </Text>
        </Flex>
        <Logout
          handler={handleSignout}
          title={`${firstName} ${lastName}`.toUpperCase()}
          styles={{
            alignItems: 'center',
          }}
        />
      </Flex>
      {/* Main content area w.r.t. routes */}
      <Switch>
        {
          SideMenuOptions.map((sideMenuOption: ISideMenuOption) => (
            <PrivateRoute
              path={sideMenuOption.path}
              component={sideMenuOption.component}
              key={sideMenuOption.path}
            />
          ))
        }
        {/* <PrivateRoute
          path="/admin/dashboard"
          component={() => (
            <Flex
              width="100%"
              height="100%"
              // Hardcoded color as mock is not available for admin portal
              backgroundColor="defaultBackgroundColor"
              padding={8}
              direction="column"
            >
              <Flex
                justifyContent="space-between"
                alignItems="center"
                marginBottom={4}
              >
                <Text
                  type={TextTypes.header}
                >
                  Default dashboard
                </Text>
              </Flex>
              <Box
                height="100%"
                backgroundColor="defaultBackgroundColor"
                flexGrow={1}
                padding={4}
              >
                Some Default content for dashboard
              </Box>
            </Flex>
          )}
        /> */}
        <Redirect from="/*" exact to="/admin/customers" />
      </Switch>
    </Grid>
  );
};

export default AdminDashboard;
