import React, { memo, useEffect, useCallback, useState } from 'react';
import {
  Box, FormControl, FormHelperText, InputProps, useToast,
} from '@chakra-ui/core';
import { useForm, Controller } from 'react-hook-form';
import InputMask from 'react-input-mask';
import LetterTabs from './LetterTabs';
import { TypeOfLetterBuilderTabs } from '../../../variables/types';
import {
  LetterApprovalStatus,
  useApproveBrandLetterMutation,
  useCreateBrandLetterRevisionMutation,
  useGetBrandLetterQuery,
} from '../../../graphql';
import { ICommonStepsPropInterface, IBuilderTab } from '../interfaces';
import {
  Input, FormLabel,
  Text, TextTypes, Loader, LoaderTypes,
} from '../../../components';
import DragDrop from '../../../components/drag-drop';
import {
  EMAIL_VALIDATION_PATTERN,
  PHONE_VALIDATION_PATTERN,
} from '../../../utils/regex';
import {
  INVALID_EMAIL,
  INVALID_PHONE_NUMBER,
  PHONE_NUMBER_MASK_FORMAT,
  DEFAULT_LETTER_BUILDER_IMAGE,
  UNSAVED_REVISION_REQUEST_PROMPT_MSG,
} from '../../../variables/contants';
import { getBrandID } from '../../../utils/files';
import getErrorMessage from '../../../utils/getErrorMessage';
import {
  navigationBlockedPayload as handleNavigationBlocked,
} from '../../../variables/globalVariables';

export interface State {
  id: string;
  address: string;
  phoneNumber: number;
  email: string;
}

const readFile = (file: File) => new Promise((resolve) => {
  const reader = new FileReader();

  // This is called when finished reading
  reader.onload = (event) => {
    resolve({
      // These are attributes like size, name, type, ...
      /* Using lastModified (timestamp) instead of lastModifiedDate
        as lastModifiedDate is deprecated
        https://caniuse.com/mdn-api_file_lastmodifieddate
      */
      lastModified: file.lastModified,
      name: file.name,
      size: file.size,
      type: file.type,

      // This is the files content as base64
      src: event?.target?.result,
    });
  };

  if (file.type.indexOf('text/') === 0 || file.type === 'application/json') {
    reader.readAsText(file);
  } else if (file.type.indexOf('image/') === 0) {
    reader.readAsDataURL(file);
  } else {
    reader.readAsBinaryString(file);
  }
});

const LetterBuilder: React.FC<ICommonStepsPropInterface> = (
  {
    advancingStepLoader,
    handleNextStep,
    refetchBrandStatus,
  }: ICommonStepsPropInterface,
) => {
  const {
    data,
    loading: brandLettersLoading,
    error: brandLettersError,
    refetch,
  } = useGetBrandLetterQuery({
    variables: {
      id: getBrandID(),
    },
    fetchPolicy: 'network-only',
  });
  const [createBrandLetterRevision, {
    loading: brandLetterRevisionLoading,
    error: brandLetterRevisionError,
  }] = useCreateBrandLetterRevisionMutation();
  const [
    approveBrandLetter,
    {
      loading: approveBrandLetterLoading,
    },
  ] = useApproveBrandLetterMutation();
  const {
    register, errors, control, watch,
  } = useForm({ mode: 'onBlur' });
  // Added watch over all fields to update live preview.
  const formValues = watch();
  const [letterImage, setLetterImage] = useState<string>(
    DEFAULT_LETTER_BUILDER_IMAGE,
  );

  /*
   * This 'revisionRequestTextPayload' is used to manage Revision Request
   * text-box text state.
   */
  const [
    revisionRequestTextPayload, setRevisionRequestTextPayload,
  ] = useState<{requested: boolean, content: string, id: string}[]>([]);

  const getTabs = useCallback(() => {
    const tabs = data?.brand?.brandLetters?.edges?.map((letter) => (
      {
        id: `impact_letter_tab_${letter?.node?.stage}`,
        brandLetterId: letter?.node?.id!,
        title: `Impact Letter ${letter?.node?.stage}`,
        letterContent: letter?.node?.template?.toString(),
        approvalStatus: letter?.node?.approvalStatus!,
        type: TypeOfLetterBuilderTabs.LetterBuilder,
        /* revision: {
          requested: true,
          content: letter
          ?.node?.brandLetterRevision?.edges[0]?.node?.templateRevision || '',
        }, */
        brandLetterRevisionContent: letter
          ?.node?.brandLetterRevision?.edges[0]?.node?.templateRevision || '',
      }
    )) || [];
    tabs.push({
      id: 'review_tab',
      brandLetterId: '',
      title: 'Review',
      letterContent: '',
      type: TypeOfLetterBuilderTabs.Review,
      brandLetterRevisionContent: '',
      /*
       * NOTE: approvalStatus for ReviewTab is not used but value given for
       * type-safe code.
      */
      approvalStatus: LetterApprovalStatus.Generated,
      // revision: {
      //   requested: false,
      //   content: '',
      // },
    });
    return tabs;
  }, [data]);

  const [builderTabs, setBuilderTabs] = useState<IBuilderTab[]>([
    ...getTabs(),
  ]);

  useEffect(() => {
    setBuilderTabs(getTabs());
  }, [getTabs]);

  useEffect(() => {
    const unSavedText: boolean = revisionRequestTextPayload
      .some((revision) => revision.content);
    handleNavigationBlocked({
      isNavigationBlocked: unSavedText,
      navigationBlockMessage: UNSAVED_REVISION_REQUEST_PROMPT_MSG,
    });
  }, [revisionRequestTextPayload]);

  /*
    * Populating revisionRequestTextPayload for handling Revision Request
    * Text-box text content in impact letter tab seperately.
  */
  useEffect(() => {
    /*
     * Only run this effect for the initial load of the page as running it every
     * time 'data' changes will overwrite 'revisionRequestTextPayload' on each
     * action of 'Revise' or 'Approve' button click.
     */
    if (
      data?.brand?.brandLetters?.edges
      && revisionRequestTextPayload.length === 0
    ) {
      const revisionRequestArray = data.brand.brandLetters.edges
        .map((letter) => ({
          content: '',
          requested: false,
          id: letter?.node?.id!,
        }));
      setRevisionRequestTextPayload([
        ...revisionRequestArray,
        {
          content: '',
          id: 'review_tab',
          requested: false,
        },
      ]);
    }
  }, [data, revisionRequestTextPayload]);

  const handleFileDrop = async (acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      const image = await readFile(acceptedFiles?.[0]) as any;
      setLetterImage(image?.src);
      // TODO: Accepted Files logic here
    } else {
      setLetterImage(DEFAULT_LETTER_BUILDER_IMAGE);
    }
  };

  const toast = useToast();

  const handleCreateRevisionRequest = async (brandLetterId: string,
    revisionContent: string) => {
    try {
      await createBrandLetterRevision({
        variables: {
          input: {
            id: brandLetterId,
            templateRevision: revisionContent,
          },
        },
      });
      toast({
        title: 'Revision request received!',
        description: 'Revision request accepted successfully.',
        status: 'success',
      });
      handleClearRevisionRequestText(brandLetterId);
      refetchBrandStatus();
      refetch();
    } catch {
      toast({
        title: 'Error!',
        description: brandLetterRevisionError?.message,
        status: 'error',
      });
    }
  };

  const handleApproveBrandLetter = async (
    brandLetterId: string,
  ) => {
    try {
      const response = await approveBrandLetter({
        variables: {
          input: {
            id: brandLetterId,
          },
        },
      });
      const stage = response.data?.approveBrandLetter?.brandLetter?.stage!;
      toast({
        title: 'Impact Letter approved!',
        description: `Impact Letter ${stage} approved successfully.`,
        status: 'success',
      });
      handleClearRevisionRequestText(brandLetterId);
      refetchBrandStatus();
      refetch();
    } catch (error) {
      toast({
        title: 'Error!',
        description: getErrorMessage(error),
        status: 'error',
      });
    }
  };

  const handleClearRevisionRequestText = useCallback(
    (brandLetterId: string) => {
      setRevisionRequestTextPayload(
        (prevRevisionPayload) => prevRevisionPayload.map((revisionPayload) => {
          if (revisionPayload.id === brandLetterId) {
            const updatedRevisionRequestPayload = { ...revisionPayload };
            return {
              ...updatedRevisionRequestPayload,
              ...{
                content: '',
                requested: false,
              },
            };
          }
          return revisionPayload;
        }),
      );
    },
    [],
  );

  const handleBuilderTabRevisionChanges = useCallback(
    (tabId: string, revisionContent: string) => {
      const updatedPayload: {
        requested: boolean;
        content: string;
        id: string;
      }[] = revisionRequestTextPayload.map((revisionPayload) => {
        if (revisionPayload.id === tabId) {
          const updatedRevisionRequestPayload = { ...revisionPayload };
          const payload = {
            ...updatedRevisionRequestPayload,
            ...{
              content: revisionContent,
              requested: !!(revisionContent),
            },
          };
          return payload;
        }
        return revisionPayload;
      });
      setRevisionRequestTextPayload(updatedPayload);
    }, [revisionRequestTextPayload],
  );

  if (brandLettersLoading) {
    return <Loader type={LoaderTypes.FullViewModal} large />;
  }

  if (brandLettersError) {
    toast({
      title: 'Error!',
      description: brandLettersError.message,
      status: 'error',
    });
  }

  return (
    <>
      <Box style={{
        gridColumnStart: 1,
        padding: '0 16px',
        margin: '0 auto',
        maxWidth: '500px',
      }}
      >
        <Text
          marginBottom={6}
          type={TextTypes.header}
        >
          Personalize messaging with your custom letterhead
        </Text>
        <Text
          marginY={6}
          type={TextTypes.body}
        >
          After our proprietary AI has detected illegitimate
          storefronts it sends them tested and refined impact letters
          to persuade them to remove their listings.
        </Text>
        <Text
          marginY={6}
          type={TextTypes.body}
        >
          A key ingredient is sending the impact letters on
          letterhead that includes the company logo and details. At a
          minimum, please provide a high resolution image of your logo below.
        </Text>
        <Text
          marginY={6}
          type={TextTypes.body}
        >
          (A live preview will be displayed at the top of the letterhead)
        </Text>
        <Box paddingY={3}>
          <DragDrop
            fileFormat={['.png', '.jpg']}
            onFileDrop={handleFileDrop}
            multiple={false}
            // maxFileSize is in bytes ; so 2 MB is 2048 * 1024
            maxFileSize={2048 * 1024}
          />

        </Box>

        <Text
          as="span"
          marginY={6}
          type={TextTypes.secondaryHeader}
          paddingRight={2}
        >
          Letterhead Details
        </Text>
        <Text
          as="span"
          type={TextTypes.disabled}
        >
          optional
        </Text>
        <Text
          marginY={6}
          type={TextTypes.body}
        >
          Additional information pertaining to your company is
          optional but highly recommended.
        </Text>

        <Box flexGrow={1} paddingY={4}>
          <Box style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
          >
            <FormControl marginBottom={4}>
              <FormLabel htmlFor="address">
                Address
              </FormLabel>
              <Input
                name="address"
                type="text"
                id="address"
                aria-describedby="address-helper-text"
                ref={register()}
              />
              {
                errors?.address && (
                  <FormHelperText fontSize="xs" id="email-helper-text">
                    {errors.address.message}
                  </FormHelperText>
                )
              }
            </FormControl>
            <FormControl marginBottom={4}>
              <FormLabel htmlFor="phoneNumber">
                Phone Number
              </FormLabel>
              <Controller
                control={control}
                mask={PHONE_NUMBER_MASK_FORMAT}
                maskChar={null}
                name="phoneNumber"
                type="tel"
                defaultValue={null}
                rules={
                  {
                    // required: {
                    //   value: true,
                    //   message: PHONE_NUMBER_REQUIRED,
                    // },
                    pattern: {
                      value: PHONE_VALIDATION_PATTERN,
                      message: INVALID_PHONE_NUMBER,
                    },
                  }
                }
                as={(
                  <InputMask
                    mask={PHONE_NUMBER_MASK_FORMAT}
                    alwaysShowMask
                    type="tel"
                    name="phoneInputMask"
                  >
                    {
                      (inputProps: InputProps) => (
                        <Input
                          name="phoneInput"
                          type="tel"
                          id="phoneNumber"
                          aria-describedby="phoneNumber-helper-text"
                          // eslint-disable-next-line max-len
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...inputProps}
                        />
                      )
                    }
                  </InputMask>
                )}
              />
              {
                errors?.phoneNumber && (
                  <FormHelperText fontSize="xs" id="email-helper-text">
                    {errors.phoneNumber.message}
                  </FormHelperText>
                )
              }
            </FormControl>
            <FormControl marginBottom={4}>
              <FormLabel htmlFor="email">
                Email
              </FormLabel>
              <Input
                name="email"
                type="email"
                id="email"
                aria-describedby="email-helper-text"
                ref={register({
                  // required: { value: true, message: EMAIL_REQUIRED },
                  pattern: {
                    value: EMAIL_VALIDATION_PATTERN,
                    message: INVALID_EMAIL,
                  },
                })}
              />
              {
                errors?.email && (
                  <FormHelperText fontSize="xs" id="email-helper-text">
                    {errors.email.message}
                  </FormHelperText>
                )
              }
            </FormControl>
          </Box>
        </Box>
      </Box>
      <Box style={{
        gridRowStart: 1,
        width: '100%',
        maxWidth: '650px',
      }}
      >
        <LetterTabs
          address={formValues.address}
          phoneNumber={formValues.phoneNumber}
          email={formValues.email}
          letterImage={letterImage}
          builderTabs={builderTabs}
          advancingUserLoader={advancingStepLoader}
          handleNextStep={handleNextStep}
          refetch={refetch}
          brandLetterRevisionLoading={brandLetterRevisionLoading}
          handleCreateRevisionRequest={handleCreateRevisionRequest}
          approveBrandLetterLoading={approveBrandLetterLoading}
          handleApproveBrandLetter={handleApproveBrandLetter}
          handleRevisionChanges={handleBuilderTabRevisionChanges}
          revisionRequestTextPayload={revisionRequestTextPayload}
          refetchBrandStatus={refetchBrandStatus}
        />
      </Box>
    </>
  );
};
export default memo(LetterBuilder);
