import React, { useEffect } from 'react';
import {
  Flex,
  FormControl, FormHelperText, FormLabel,
  Modal, ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay, Radio, RadioGroup, Select, Textarea,
  useToast,
} from '@chakra-ui/core';
import { useForm } from 'react-hook-form';
import styled from '@emotion/styled';
import {
  DefaultTemplateFragment,
  LetterType,
  useCreateDefaultTemplateMutation,
  useUpdateDefaultTemplateMutation,
} from '../../../graphql';
import { Button, ButtonTypes } from '../../../components';
import getErrorMessage from '../../../utils/getErrorMessage';
import colors from '../../../variables/colors';

interface Props {
  templateTitle: string,
  template?: DefaultTemplateFragment | null,
  isOpen: boolean;
  onClose: (template?: DefaultTemplateFragment) => void;
}

const StyledRadio = styled(Radio)`
  div[type="radio"] {
    border-color: ${colors.switches}
  }
`;

const TemplateModal: React.FC<Props> = ({
  templateTitle, template, isOpen, onClose,
}: Props) => {
  const [
    createDefaultTemplate, { loading: creating },
  ] = useCreateDefaultTemplateMutation();
  const [
    updateDefaultTemplate, { loading: updating },
  ] = useUpdateDefaultTemplateMutation(); // TODO -- need to add loading
  const { register, handleSubmit, errors, reset } = useForm<{
    template: string,
    stage: number,
    letterFormat: LetterType,
  }>({
    defaultValues: {
      template: template?.template,
      stage: template?.stage,
      letterFormat: template?.letterFormat,
    },
  });

  useEffect(() => {
    if (template) {
      reset(template);
    } else {
      reset({
        template: '',
        stage: 1,
        letterFormat: LetterType.Textbox,
      });
    }
  }, [template, reset]);

  const toast = useToast();

  const onUpdateDefaultTemplate = async (formValues: Record<string, any>) => {
    try {
      if (template) {
        const result = await updateDefaultTemplate({
          variables: {
            input: {
              id: template?.id!,
              template: formValues.template,
              stage: Number(formValues.stage),
              letterFormat: formValues.letterFormat,
            },
          },
        });
        onClose(result?.data?.updateDefaultTemplate?.defaultTemplate!);
      } else {
        const result = await createDefaultTemplate({
          variables: {
            input: {
              template: formValues.template,
              stage: Number(formValues.stage),
              letterFormat: formValues.letterFormat,
            },
          },
        });
        onClose(result?.data?.createDefaultTemplate?.defaultTemplate!);
      }
      reset();
      const message = `Default Template ${template
        ? 'Updated'
        : 'Created'} Successfully!`;
      toast({
        title: message,
        description: message,
        status: 'success',
      });
    } catch (error) {
      if (error) {
        toast({
          title: 'Error!',
          description: getErrorMessage(error),
          status: 'error',
        });
      }
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => onClose()}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{templateTitle}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form
            onSubmit={handleSubmit(onUpdateDefaultTemplate)}
            noValidate
            style={{
              width: '100%',
            }}
          >
            <FormControl marginBottom={4} isRequired>
              <FormLabel>
                Stage
              </FormLabel>
              <Select
                defaultValue={1}
                isDisabled={creating || updating}
                onChange={() => {
                }}
                name="stage"
                ref={
                  register({
                    required: true,
                  })
                }
              >
                <option value={1}>1</option>
                <option value={2}>2</option>
                <option value={3}>3</option>
              </Select>
            </FormControl>
            <FormControl width="100%" marginBottom={4} isRequired>
              <FormLabel htmlFor="letterFormat" aria-required>
                Letter Format
              </FormLabel>
              <RadioGroup
                defaultValue={LetterType.Textbox}
                name="letterFormat"
                id="letterFormat"
                aria-describedby="contact-first-name-helper-text"
                ref={
                  register({
                    required: true,
                  })
                }
              >
                <StyledRadio
                  value={LetterType.Image}
                  name="letterFormat[0]"
                  ref={
                    register({
                      required: true,
                    })
                  }
                >
                  Image
                </StyledRadio>
                <StyledRadio
                  value={LetterType.Textbox}
                  name="letterFormat[1]"
                  ref={
                    register({
                      required: true,
                    })
                  }
                >
                  Textbox
                </StyledRadio>
              </RadioGroup>
              {
                errors.letterFormat && (
                  <FormHelperText
                    fontSize="xs"
                    id="letter-format-helper-text"
                  >
                    *Letter Format is required.
                  </FormHelperText>
                )
              }
            </FormControl>
            <FormControl width="100%" marginBottom={4} isRequired>
              <FormLabel htmlFor="contactLastName" aria-required>
                Template
              </FormLabel>
              <Textarea
                name="template"
                type="text"
                id="template"
                aria-describedby="template-text"
                ref={
                  register({
                    required: true,
                  })
                }
                isDisabled={creating || updating}
              />
              {
                errors.template && (
                  <FormHelperText
                    fontSize="xs"
                    id="contact-last-name-helper-text"
                  >
                    *Template is required
                  </FormHelperText>
                )
              }
            </FormControl>
            <Flex
              justifyContent="center"
            >
              <Button
                type="submit"
                buttonType={ButtonTypes.Primary}
                marginBottom={6}
                width="100%"
                maxWidth="250px"
                isLoading={creating || updating}
              >
                {
                  template ? 'Update Template' : 'Create Template'
                }
              </Button>
            </Flex>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default React.memo(TemplateModal);
