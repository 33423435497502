import React, { memo/* , useRef */ } from 'react';
import {
  Box,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from '@chakra-ui/core';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import {
  Text, Button,
  ButtonTypes, TextTypes, Checkbox, CheckboxTypes, Loader, LoaderTypes,
} from '../../../components';
import { ISeller } from '../interfaces';

interface Props {
  sellers: ISeller[];
  isLoading: boolean;
  advancingUserLoader: boolean;
  authorizeSeller: (seller: ISeller) => void;
  toggleContactSeller: (seller: ISeller) => void;
  handleNextStep: () => void;
  onLoadMore: () => void;
}

interface RenderSellerListProps {
  sellers: ISeller[];
  authorizeSeller: (seller: ISeller) => void;
  toggleContactSeller: (seller: ISeller) => void;
}

const RenderSellerList: React.FC<RenderSellerListProps> = ({
  sellers,
  authorizeSeller,
  toggleContactSeller,
}: RenderSellerListProps) => (
  <>
    {
      sellers.map((
        seller: ISeller, currentIndex: number,
      ) => (
        <Flex
          key={seller.sellerId}
          justifyContent="space-between"
          borderBottom={`
        ${(sellers?.length - 1 !== currentIndex) && '1px solid'}
        `}
          borderColor="lightBorderColor"
          paddingY={3}
          paddingX={2}
        >
          <Text type={TextTypes.body}>
            {seller.sellerName}
          </Text>
          <Flex alignItems="center">
            <Menu>
              <MenuButton
                marginX={3}
                as={Icon}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...{ name: 'options', color: 'activePrimaryColor' }}
              />
              <MenuList placement="bottom-end">
                <MenuItem
                  alignItems="center"
                  onClick={() => toggleContactSeller({
                    ...seller,
                    ...{ contactSeller: !seller.contactSeller },
                  })}
                >
                  <Checkbox
                    isChecked={!seller.contactSeller}
                    onChange={() => toggleContactSeller({
                      ...seller,
                      ...{ contactSeller: !seller.contactSeller },
                    })}
                    id="contactSeller"
                    checkboxType={CheckboxTypes.Primary}
                    mr={2}
                  />
                  Don&apos;t Contact Seller
                </MenuItem>
              </MenuList>
            </Menu>
            <Checkbox
              key={seller.sellerId}
              isChecked={seller.isAuthorized}
              onChange={() => authorizeSeller({
                ...seller, ...{ isAuthorized: !seller.isAuthorized },
              })}
              id="isAuthorized"
              checkboxType={CheckboxTypes.Primary}
            />
          </Flex>
        </Flex>
      ))
    }
  </>
);

const StoreDetails = ({
  sellers,
  isLoading,
  advancingUserLoader,
  authorizeSeller,
  toggleContactSeller,
  handleNextStep,
  onLoadMore,
}: Props) => {
  const containerRef = useBottomScrollListener(onLoadMore);

  return (
    <Box
      padding={4}
      paddingTop={0}
      width="100%"
      height="100%"
    >
      <Box
        border={1}
        borderRadius={5}
        borderStyle="solid"
        borderColor="defaultBorderColor"
        maxHeight={550}
        maxWidth={650}
        minWidth={290}
        overflow="auto"
        marginX="auto"
        marginBottom={10}
        padding={3}
        paddingTop={0}
      >
        <Flex
          justifyContent="space-between"
          borderBottom="1px solid #333333"
          position="sticky"
          paddingBottom={1}
          paddingTop={5}
          top={0}
          /**
           * Added hardcoded value as we are adding code just
           * to avoid visibility of overlapping content
           */
          background="white"
        >
          <Text type={TextTypes.tertiaryHeader}>
            Storefront
          </Text>
          <Text type={TextTypes.tertiaryHeader}>
            Authorized
          </Text>
        </Flex>
        <Box
          minHeight={380}
          height={380}
          overflowY="auto"
          position="relative"
          paddingRight={2}
          ref={containerRef}
        >
          {!isLoading
            ? (
              <RenderSellerList
                sellers={sellers}
                authorizeSeller={authorizeSeller}
                toggleContactSeller={toggleContactSeller}
              />
            )
            : <Loader type={LoaderTypes.Content} />}
          {
            !isLoading && sellers.length === 0 && (
              <Flex
                alignItems="center"
                justifyContent="center"
                height="inherit"
              >
                <Text fontStyle="italic">
                  {`No sellers found for the current brand. Please add sellers
                    manually from this page or upload a product list file from
                    the CSV Upload page`}
                </Text>
              </Flex>
            )
          }
        </Box>
      </Box>
      <Flex
        flexDirection="row"
        justifyContent="center"
      >
        <Button
          isDisabled={isLoading || advancingUserLoader}
          buttonType={ButtonTypes.Primary}
          width="100%"
          maxWidth={400}
          onClick={handleNextStep}
          isLoading={advancingUserLoader}
        >
          Save & Continue
        </Button>
      </Flex>
    </Box>
  );
};

export default memo(StoreDetails);
